html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

/* break words for react-markdown renderer */
.break-word {
  word-break: break-word;
}

/* create responsive images */
.responsive {
  max-width: 100%;
  height: auto;
}

/* override quotes for application */
blockquote {
  font-style: italic;
	margin: 0.5rem 1.5rem;
	color: #999;
	border-left: 0.2rem solid #999;
	padding-left: 0.8rem; 
}

/*override default `details` tag*/
details {
  margin-bottom: 1.5rem;

  summary {
    text-decoration: underline;
    color: #0d6efd;
  }
}

details[open] {
  & summary {
    margin-bottom: 1rem;
  }
}